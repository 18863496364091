<template>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/users" class="flex items-center" data-cy="users">
      <se-icon name="user" :size="20" class="mr-16" />
      <span>Users</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/inbox" class="flex items-center" data-cy="inbox">
      <se-icon name="mail" :size="20" class="mr-16" />
      <span>Inbox <span v-if="getUnreadInbox()">({{ getUnreadInbox() }})</span></span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/categories" class="flex items-center" data-cy="categories">
      <se-icon name="eye" :size="20" class="mr-16" />
      <span>Categories</span>
    </Link>
  </div>

  <div class="w-full" v-bind="$attrs">
    <div class="dropdown-nav-item" @click="isSettingsOpen = !isSettingsOpen">
      <a class="flex items-center justify-between w-full xl:w-auto">
        <div class="flex items-center">
          <se-icon name="settings" :size="20" class="mr-16" />
          <span>Settings</span>
        </div>

        <se-icon name="chevron-down" :size="22" class="ml-4 hidden xl:!block xl:visible" />
        <se-icon name="chevron-right" :size="20" class="ml-4 xl:hidden self-end !mb-2" />
      </a>
      <nav :class="{ 'is-open': isSettingsOpen }">
        <div class="p-16 border-b border-neutral-20 w-full mb-8 lg:hidden" @click.stop="isSettingsOpen = false">
          <div class="cursor-pointer">
            <se-icon name="arrow-left" class="text-black" :size="24" />
          </div>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/settings" class="!flex !items-center" data-cy="settings">
            <se-icon name="settings" :size="20" class="mr-16" />
            <span>Settings</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/promotions" class="!flex items-center" data-cy="promotions">
            <se-icon name="percent" :size="20" class="mr-16" />
            <span>Promotions</span>
          </Link>
        </div>
      </nav>
    </div>
  </div>

  <div class="w-full" v-bind="$attrs">
    <div class="dropdown-nav-item" @click="isReportsOpen = !isReportsOpen">
      <a class="flex items-center justify-between w-full xl:w-auto">
        <div class="flex items-center">
          <se-icon name="bar-chart" :size="20" class="mr-16" />
          <span>Reports</span>
        </div>

        <se-icon name="chevron-down" :size="22" class="ml-4 hidden xl:!block xl:visible" />
        <se-icon name="chevron-right" :size="20" class="ml-4 xl:hidden self-end !mb-2" />
      </a>
      <nav :class="{ 'is-open': isReportsOpen }">
        <div class="p-16 border-b border-neutral-20 w-full mb-8 lg:hidden" @click.stop="isReportsOpen = false">
          <div class="cursor-pointer">
            <se-icon name="arrow-left" class="text-black" :size="24" />
          </div>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/withdraws" class="!flex !items-center" data-cy="settings">
            <se-icon name="receipt" :size="20" class="mr-16" />
            <span>Withdraws</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/reports" class="!flex !items-center" data-cy="settings">
            <se-icon name="bar-chart" :size="20" class="mr-16" />
            <span>Reports</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/sales" class="!flex !items-center" data-cy="settings">
            <se-icon name="badge-dollar-sign" :size="20" class="mr-16" />
            <span>Sales</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/info-sharing" class="!flex !items-center" data-cy="settings">
            <se-icon name="arrow-right" :size="20" class="mr-16" />
            <span>Info Sharing</span>
          </Link>
        </div>
      </nav>
    </div>
  </div>

  <div class="w-full" v-bind="$attrs">
    <div class="dropdown-nav-item" @click="isFunctionalitiesOpen = !isFunctionalitiesOpen">
      <a class="flex items-center justify-between w-full xl:w-auto">
        <div class="flex items-center">
          <se-icon name="cog" :size="20" class="mr-16" />
          <span>Functionalities</span>
        </div>

        <se-icon name="chevron-down" :size="22" class="ml-4 hidden xl:!block xl:visible" />
        <se-icon name="chevron-right" :size="20" class="ml-4 xl:hidden self-end !mb-2" />
      </a>
      <nav :class="{ 'is-open': isFunctionalitiesOpen }">
        <div class="p-16 border-b border-neutral-20 w-full mb-8 lg:hidden" @click.stop="isFunctionalitiesOpen = false">
          <div class="cursor-pointer">
            <se-icon name="arrow-left" class="text-black" :size="24" />
          </div>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/new-admin" class="!flex !items-center" data-cy="settings">
            <se-icon name="user-plus" :size="20" class="mr-16" />
            <span>New admin</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/functions" class="!flex !items-center" data-cy="settings">
            <se-icon name="cog" :size="20" class="mr-16" />
            <span>Functions</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/refill" class="!flex !items-center" data-cy="settings">
            <se-icon name="ticket-slash" :size="20" class="mr-16" />
            <span>Refund</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/mass-messaging" class="!flex !items-center" data-cy="settings">
            <se-icon name="send" :size="20" class="mr-16" />
            <span>Mass messaging</span>
          </Link>
        </div>
        <div class="w-full">
          <Link href="/admin-dashboard/data-import" class="!flex !items-center" data-cy="settings">
            <se-icon name="upload" :size="20" class="mr-16" />
            <span>Import data</span>
          </Link>
        </div>
      </nav>
    </div>
  </div>

  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/chats" class="flex items-center" data-cy="account-settings">
      <se-icon name="message-circle" :size="20" class="mr-16" />
      <span>Chats</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/inbox" class="flex items-center" data-cy="account-settings">
      <se-icon name="inbox" :size="20" class="mr-16" />
      <span>Inboxes</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/transactions" class="flex items-center" data-cy="account-settings">
      <se-icon name="arrow-left-right" :size="20" class="mr-16" />
      <span>Transactions</span>
    </Link>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Link from '../../../../components/Link.vue';
import SeIcon from '../../../ui-kit/icon/Icon.vue';
import { usersStore } from '../../../../store/users';

const user = usersStore();
const { getUnreadInbox } = user;

const isSettingsOpen = ref(false);
const isReportsOpen = ref(false);
const isFunctionalitiesOpen = ref(false);

</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenuAdmin',
};
</script>
