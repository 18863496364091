<template>
  <se-header-menu-admin v-if="isAdmin || (!isSupport && !isSales && $route.path.startsWith('/admin-dashboard'))" />
  <se-header-menu-support v-else-if="isSupport" />
  <se-header-menu-sales v-else-if="isSales" />
  <se-header-menu-user v-else-if="isUser || (!isAdvisor && $route.path.startsWith('/dashboard'))" />
  <se-header-menu-advisor v-else-if="isAdvisor" />
  <se-header-menu-guest v-else />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { usersStore } from '../../../store/users';
import { getGlobalProperties } from '../../helpers/getGlobalProperties';
import SeHeaderMenuUser from './menus/HeaderMenuUser.vue';
import SeHeaderMenuGuest from './menus/HeaderMenuGuest.vue';
import SeHeaderMenuAdvisor from './menus/HeaderMenuAdvisor.vue';
import SeHeaderMenuSupport from './menus/HeaderMenuSupport.vue';
import SeHeaderMenuSales from './menus/HeaderMenuSales.vue';
import SeHeaderMenuAdmin from './menus/HeaderMenuAdmin.vue';

const {
  isAdmin, isSupport, isUser, isAdvisor, isSales
} = storeToRefs(usersStore());

const { $route } = getGlobalProperties();
</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenu',
};
</script>
