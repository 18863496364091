<template>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/sales" class="flex items-center" data-cy="sales">
      <se-icon name="dollar-sign" :size="20" class="mr-16" />
      <span>Sales</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/reports" class="flex items-center" data-cy="advisors">
        <se-icon name="bar-chart" :size="20" class="mr-16" />
        <span>Reports</span>
    </Link>
  </div>
</template>

<script lang="ts" setup>
import Link from '../../../../components/Link.vue';
import SeIcon from '../../../ui-kit/icon/Icon.vue';

</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenuSales',
};
</script>
