/** Statistics and analytics.
*/

import config from '/config'

class Analytics {

    constructor(app) {
    }

    // Initializes Google Tag Manager.
    init(cookiesAllowed) {

        this.setConsent(cookiesAllowed);

        // Google Tag Manager
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", config.google.gtm)
        // End Google Tag Manager
    }

    // Function to set consent based on the user's choice
    setConsent(cookiesAllowed) {
        // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || []
        function gtag() { dataLayer.push(arguments) }

        // Set default consent to 'denied' as a placeholder
        // Determine actual values based on your own requirements
        if (cookiesAllowed) {
            gtag('consent', 'default', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } else {
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
        }
    }
    // Function to update consent based when user accepts cookies
    updateConsent() {
        // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || []
        function gtag() { dataLayer.push(arguments) }

        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });
    }

    // Sends an event to Google Tag Manager.
    sendEvent(eventName, eventData) {
        // Specifying data for purchase event, triggered in Google Tag Manager on this page.
        eventData.event = eventName
        if (!window.dataLayer) {
            // considering case when Google tag manager is not initialized yet.
            window.dataLayer = []
        }
        window.dataLayer.push(eventData)
        console.log('gtm', `${eventName} event sent`, eventData)
    }

    // Events

    // Sends trigger for Purchase and StartTrial Events.
    sendPurchaseEvent(userEmail, userId, transactionId, amount, currency = 'USD') {
        this.sendEvent('PurchaseCompleted', {
            // Facebook Pixel-specific data.
            facebook_pixel_id: config.facebook.pixel,
            facebook_event_name: 'Purchase',
            facebook_event_id: 'PRC_' + transactionId,
            facebook_event_data: {
                value: amount,
                currency: currency,
                user_email: userEmail,
                user_id: userId,
            },

            // Other GTM integrations data.
            currency: currency,
            value: amount,
            user_data: {
                email_address: userEmail,
            },
            user_id: userId,
            ecommerce: {
                transaction_id: transactionId,
                value: amount,
                currency: currency,
                purchase: {
                    actionField: {
                        id: transactionId,
                        revenue: amount,
                    },
                    products: [
                        {
                            name: `Add funds ${amount}`,
                            id: amount,
                            price: amount,
                            quantity: 1,
                        },
                    ],
                },
                user: {
                    id: userId,
                },
            },
        })
    }

    // Sends trigger for Contact Event.
    sendStartChatEvent(userEmail, userId, advisorUrl) {
        let date = new Date()
        this.sendEvent('StartChat', {
            // Facebook Pixel-specific data.
            facebook_pixel_id: config.facebook.pixel,
            facebook_event_name: 'Contact',
            facebook_event_id: 'SC_' + advisorUrl + '_' + date.getFullYear() + String(date.getMonth()).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0') + String(date.getMinutes()).padStart(2, '0'),
            facebook_event_data: {
                user_email: userEmail,
                user_id: userId,
            },

            // Other GTM integrations data.
            user_data: {
                email_address: userEmail,
            },
            user_id: userId,
        })
    }

    // Sends trigger for Add To Cart Event.
    sendPaymentFailedEvent(userEmail, userId) {
        let date = new Date()
        this.sendEvent('PaymentFailed', {
            // Facebook Pixel-specific data.
            facebook_pixel_id: config.facebook.pixel,
            facebook_event_name: 'AddToWishlist',
            facebook_event_id: 'PF_' + userId + '_' + date.getFullYear() + String(date.getMonth()).padStart(2, '0') + String(date.getDate()).padStart(2, '0') + String(date.getHours()).padStart(2, '0') + String(date.getMinutes()).padStart(2, '0'),
            facebook_event_data: {
                user_email: userEmail,
                user_id: userId,
            },

            // Other GTM integrations data.
            user_data: {
                email_address: userEmail,
            },
            user_id: userId,
        })
    }

    sendCompleteRegistrationEvent(userEmail, userId) {
        this.sendEvent('RegistrationCompleted', {
            // Facebook Pixel-specific data.
            facebook_pixel_id: config.facebook.pixel,
            facebook_event_name: 'CompleteRegistration',
            facebook_event_id: 'CRG_' + userId,
            facebook_event_data: {
                user_email: userEmail,
                user_id: userId,
            },

            // Other GTM integrations data.
            user_data: {
                email_address: userEmail,
            },
            user_id: userId,
        })
    }
}

export default {
    install: function (app, options) {
        const analytics = new Analytics(app)
        app.config.globalProperties.analytics = analytics
        app.config.globalProperties.$analytics = analytics
        app.$analytics = analytics
    }
}
